import React from 'react';

import Link from 'next/link';

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import ImageLoader from '@client/components/general/ImageLoader';
import { navigationPaths } from '@client/constants/navigation';
import { colors } from '@constants/colors';

import LoginImage from '@public/images/astro-planet.png';

type Props = {
  title?: string;
  description?: React.ReactNode | string;
  redirectButtons?: { name: string; path: string }[];
  children: React.ReactNode;
};
/**
 *
 * All props are optional, besides children. If properties are left blank, this component will default to the login panel.
 * @returns JSX.Element
 */
const LoginPanelContainer = ({ title, description, redirectButtons, children }: Props) => {
  const { forgotPassword, klantWorden, klantWordenDemo } = navigationPaths.public;
  const theme = useTheme();
  const smallOrBiggerBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const environment = process.env.NODE_ENV;
  return (
    <>
      <Container
        sx={{
          mt: 'auto',
          mb: 'auto',
          height: '100%',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}>
        <Paper elevation={6} sx={{ width: '100%', height: '100%' }}>
          <Grid overflow='hidden' sx={{ borderRadius: 1 }} container>
            {/* >>>>>> IMAGE CONTAINER >>>>>> */}
            {!mobileBreakpoint && (
              <>
                <Grid
                  item
                  xs={12}
                  md={5}
                  bgcolor={colors.lightBluePaperBackground}
                  alignItems='center'
                  justifyContent='center'
                  justifyItems='center'>
                  <Box
                    sx={{
                      display: 'flex',
                      height: '100%',
                      width: '100%',
                      p: 6,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <ImageLoader
                      imageProps={{ priority: true, loading: 'eager' }}
                      imageSrc={LoginImage}
                      width={400}
                      height={400}
                      alt={''}
                    />
                  </Box>
                </Grid>
              </>
            )}

            {/* >>>>>> REGISTRATION INFORMATION PANEL >>>>>> */}
            <Grid item xs={12} md={7}>
              <Container
                maxWidth='sm'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    placeContent: 'center',
                    mt: '2em',
                    pl: '2em',
                    pr: '2em',
                  }}>
                  <Typography variant='h3' component='h2' align='center' color={'primary'}>
                    {title ?? 'Astro'}
                  </Typography>
                </Box>
                {description && typeof description === 'string' && (
                  <Box
                    sx={{
                      display: 'flex',
                      placeContent: 'center',
                      flexDirection: 'column',
                      gap: 1,
                      pl: 2,
                      pr: 2,
                    }}>
                    <Typography variant='body1' align='center'>
                      {description}
                    </Typography>
                  </Box>
                )}
                {description && typeof description !== 'string' && description}
                {!description && (
                  <Box
                    sx={{
                      display: 'flex',
                      placeContent: 'center',
                      flexDirection: 'column',
                      gap: 1,
                      pl: 2,
                      pr: 2,
                    }}>
                    <Typography variant='body1' align='center'>
                      De boekhouder voor moderne ondernemers
                    </Typography>
                  </Box>
                )}
              </Container>
              <Container
                maxWidth='sm'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 2,
                }}>
                <Divider sx={{ mt: 4, mb: 4 }} variant='fullWidth' />
                {children}
                {/* <RegistrationForm /> */}
                <Box
                  sx={{
                    mt: 2,
                    mb: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Stack
                    direction={smallOrBiggerBreakpoint ? 'row' : 'column'}
                    spacing={2}
                    sx={{ width: { xs: '100%', sm: 'auto' } }}>
                    {redirectButtons && redirectButtons.length > 0 ? (
                      <>
                        {redirectButtons.map(({ name, path }) => (
                          <Link href={path} passHref key={name}>
                            <Button variant='outlined' fullWidth>
                              {name}
                            </Button>
                          </Link>
                        ))}
                      </>
                    ) : (
                      <>
                        <Link
                          href={forgotPassword}
                          passHref
                          style={{
                            textDecoration: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                          // style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button variant='outlined' fullWidth>
                            Wachtwoord vergeten?
                          </Button>
                        </Link>
                        <Link
                          href={environment === 'development' ? klantWordenDemo : klantWorden}
                          passHref
                          style={{
                            textDecoration: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                          }}>
                          <Button variant='outlined' fullWidth>
                            Nog geen account?
                          </Button>
                        </Link>
                      </>
                    )}
                  </Stack>
                </Box>
                <Stack mb={3} alignItems={'center'} justifyContent={'center'}>
                  <Typography display='block' variant='caption'>
                    Nood aan technische ondersteuning?
                  </Typography>
                  <Stack
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={0.5}>
                    <Typography display='block' variant='caption'>
                      Contacteer ons op:{' '}
                    </Typography>
                    <Link passHref href='mailto:info@astro.tax' style={{ textDecoration: 'none' }}>
                      <Typography variant='caption'>info@astro.tax</Typography>
                    </Link>
                  </Stack>
                </Stack>
              </Container>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default LoginPanelContainer;
